import Common from "./common.js"
import 'parsleyjs'
import $ from "jquery/dist/jquery.js"

class Home extends Common {
  constructor() {
    super();
    var CI = this;
    CI.checkCookieExist()
    this.validate("#dealform");

    $(document).scroll(function() {
      var y = $(this).scrollTop();
      if (y > 600) {
        $('.floting-icon').fadeIn();
      } else {
        $('.floting-icon').fadeOut();
      }
    });

    // $(document).ready(function() {
    //   // jQuery.noConflict();

    //   $("#cookiespopup").style.display="block"
    //   var email = CI.getUrlParameter('email')
    //   $('.pre-pop').val(email)
    // });

    $( ".close-b" ).click(function() {
      $('.modal2').hide();
    })

    $( ".accept-cooky" ).click(function() {
      CI.CookieEvent()
      $.ajax({
        url: "/set_cookies",
        success: function(){
        },
      })
      $('.cookie-consent').addClass('d-none')
    })
    $('.reject-cookie').click(function(){
      $('.cookie-consent').addClass('d-none')
    })

    $('.partner-text').click(function(){
      $('.modal2').show();
    })

    $('#customCheck1').click(function(){
      CI.ToggleCheckBox()
    })
    $('.btn-default').click(function(){
      $('#dealform').parsley().whenValidate({
        group: 'block-0'
      }).done(() =>{
        $('.leadsLoader').height('100%');
        $('.loan_duration_months').val($("input[name='loan_duration_months']:checked").val())
        CI.leadLookup()
      })
    })

    $('.source').val(this.getUrlParameter('source') || "")
    $(".optinUrl").val(this.getUrlParameter("tPar[optIn]") || "https://get-a-loan.today/")
  }

  leadLookup() {
    var CI = this
    $.ajax({
      type: "POST",
      url: "/lead_lookup",
      data: {
        email: $(".email").val()
      },
      success: function(data) {
        console.log(data);
        if (data.dataExists) {
          if (data.data.incomeType) {
            CI.processDataAndRedirect(data.data)
          }else{
            CI.constructRedirectURL()
            
          }
        }else{
          CI.constructRedirectURL()
          
        }
      }
    });
  }

  constructRedirectURL() {
    const currentParams = new URLSearchParams(window.location.search);
    const loanAmount = document.querySelector('input[name="loan_amount"]').value;
    const loanDurationMonths = document.querySelector('input[name="loan_duration_months"]').value;
    const email = document.querySelector('input[name="email"]').value;
    currentParams.set('loan_amount', loanAmount);
    currentParams.set('loan_duration_months', loanDurationMonths);
    currentParams.set('email', email);
    const baseURL = "/app-t";
    const fullURL = `${baseURL}?${currentParams.toString()}`;
    console.log(fullURL);
    $('.leadsLoader').height('0%');
    window.location.href = fullURL;
    
  }

  processDataAndRedirect(data) {
    var CI = this
    const loanAmount = document.querySelector('input[name="loan_amount"]').value;  
    const loanDurationMonths = document.querySelector('input[name="loan_duration_months"]').value; 
    const paramsdealId = CI.getUrlParameter('dealId') || "";
    if (loanAmount) data['loanAmount'] = loanAmount;
    if (loanDurationMonths) data['loanDurationMonths'] = loanDurationMonths;
    if (typeof paramsdealId !== 'undefined' && paramsdealId !== null && paramsdealId !== '') {
      data['dealId'] = paramsdealId;
    }
    delete data.id;
    var currentParams = new URLSearchParams(window.location.search);
    for (let [key, value] of currentParams) {
      data[key] = value; 
    }
    var queryParams = [];
    for (var key in data) {
      if (data.hasOwnProperty(key)) {
        queryParams.push(encodeURIComponent(key) + '=' + encodeURIComponent(data[key]));
      }
    }
    var queryString = queryParams.join('&');
    var baseURL = "/app-short";
    var fullURL = baseURL + '?' + queryString;
    $('.leadsLoader').height('0%');
    window.location.href = fullURL;
    
  }

  getCookie(name) {
    var dc = document.cookie;
    var prefix = name + "=";
    var begin = dc.indexOf("; " + prefix);
    if (begin == -1) {
      begin = dc.indexOf(prefix);
      if (begin != 0) return null;
    }
    else
    {
      begin += 2;
      var end = document.cookie.indexOf(";", begin);
      if (end == -1) {
        end = dc.length;
      }
    }
    return decodeURI(dc.substring(begin + prefix.length, end));
  }

  checkCookieExist() {
    var myCookie = this.getCookie("_msuuid_55417an066410");
    if (myCookie == null) {
      $('.cookie-consent').removeClass('d-none')
    }
  }

  CookieEvent(){
    dataLayer.push({'event': 'cookieconsent'})
  }

  ToggleCheckBox(){
    var chk2 = $("input[type='checkbox'][name='partners-input']");
    chk2.prop("checked", !chk2.prop("checked"));
  }
}
export default new Home();
